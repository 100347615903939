html,
body {
    font-family: 'CaslonIonic', sans-serif !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'CaslonDoric';
    src: url('./fonts/CaslonDoric-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CaslonIonic';
    src: url('./fonts/CaslonIonic-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}