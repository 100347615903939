html,
body {
  font-family: 'CaslonIonic', sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'CaslonDoric';
  src: url('./fonts/CaslonDoric-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CaslonIonic';
  src: url('./fonts/CaslonIonic-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.container {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  }
  
.half {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.topToBottom {
  animation: slideTopToBottom 15s ease-in-out infinite;
}

@keyframes slideTopToBottom {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.image img {
  transition:transform 0.5s ease-in-out;
}

.bottomToTop {
  animation: slideBottomToTop 15s ease-in-out infinite;
}

@keyframes slideBottomToTop {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}


.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}


.image-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.image-row {
  display: flex;
}

.image-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}


.image-section {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.left-image,
.right-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

@keyframes bounce {
  0%, 100% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.2);
  }
} 

/* MObile page CSS */
/* .homepage-img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 1024px) {
  .homepage-img {
    object-fit: cover;
    height: 100vh;
  }
}

@media (max-width: 768px) {
  .homepage-img {
    object-fit: cover;
    width: 100%;
    height: 100vh;
  }
}

@media (max-width: 480px) {
  .homepage-img {
    object-fit: cover;
    width: 100%;
    height: 100vh;
  }
}


@keyframes scroll {
  0% {
    transform: translateX(100%);
}
100% {
    transform: translateX(-100%); 
}
}

.slider {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.slide-track {
  display: flex;
  width: 100%; 
  animation: scroll 10s linear infinite;
}

.slide {
  flex-shrink: 0;
  width: 100vw; 
  height: 100vh;
}

.images {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.image img {
  object-fit: cover;
  -webkit-object-fit: cover; 
}

@media (prefers-reduced-motion: reduce) {
  .mySwiper {
    transition: none;
  }
} 


/* Slideshow.css */

.slideshow-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  height: 100%;
}

.each-slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Optional: Adding transition effects */
.react-slideshow-container {
  position: relative;
}

.react-slideshow-container .slick-prev, 
.react-slideshow-container .slick-next {
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.react-slideshow-container .slick-prev {
  left: 10px;
}

.react-slideshow-container .slick-next {
  right: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .slideshow-container {
    padding: 10px;
  }

  .each-slide img {
    border-radius: 5px;
  }
}


.containers {
  width: 100%;
  margin: 0 auto;
  /* width: "100vw"; */
  height: 100svh;
  overflow: hidden; 
  list-style: none;
  padding: 0;
}
 
.slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  /* background-position: 50% 50%; */
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;

  transition: transform 1s ease-in-out;
}

.slide.left-to-right > div {
  transform: translateX(-100%);
}

.slide.right-to-left > div {
  transform: translateX(100%);
}

 
.slide span {
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  color: white;
  background: rgba(141, 174, 224, 0.5);
  text-align: center;
}
 
 